.bg-primary-hover {
  &:hover, &:active, &:focus {
    background-color: darken($primary, 7.5%) !important;
  }
}
.bg-success-hover {
  &:hover, &:active, &:focus {
    background-color: darken($success, 7.5%) !important;
  }
}
