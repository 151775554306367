//
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

// Example of a variable override to change Landkit's background color
// Remove the "//" to comment it in and see it in action!
$font-family-base: gill-sans-nova, -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Helvetica Neue", Arial, sans-serif;

// #5B3393 / rgb(91, 51, 147)
$ogat-purple: rgb(91, 51, 147) !default;
// #AD99C9 / rgb(173, 153, 201)
$ogat-purple-secondary: mix($ogat-purple, white, 50%) !default;
// #B09C78 / rgb(176, 156, 119)
$ogat-gold: #b09c78 !default;

// Web colors: prefer tints and shades of brand colors
// for tints: mix with white
// for shades: mix with black

$ogat-purple-alt: mix($ogat-purple, white, 60%) !default;

$primary: $ogat-purple;

$navbar-brand-height: 4rem;

$headings-font-weight: 500;
$headings-color: $primary;

$blockquote-small-color: #506690;
